import React from 'react';
import './App.css';

function ProjectItems() {
	return (
		<div className="Projects">
			<div className="ProjectItems">
				<div className="Example">
					<a href="https://news-scraper-qsb6ujju2.now.sh/#/" target="_blank " rel="noopener noreferrer" >
						<svg width="100" height="100" id="Capa_1" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
							<g>
								<path fill="#333" d="m497 180.75h-45.25v-165.75c0-8.284-6.716-15-15-15h-421.75c-8.284 0-15 6.716-15 15v451.875c0 24.882 20.243 45.125 45.125 45.125h421.75c24.882 0 45.125-20.243 45.125-45.125v-271.125c0-8.284-6.716-15-15-15zm-467 286.125v-436.875h391.75v436.875c0 5.304.927 10.394 2.616 15.125h-379.241c-8.34 0-15.125-6.785-15.125-15.125zm452 0c0 8.34-6.785 15.125-15.125 15.125s-15.125-6.785-15.125-15.125v-256.125h30.25z" />
								<path fill="#333" d="m376.5 60.25h-301.25c-8.284 0-15 6.716-15 15v120.5c0 8.284 6.716 15 15 15h301.25c8.284 0 15-6.716 15-15v-120.5c0-8.284-6.716-15-15-15zm-15 120.5h-271.25v-90.5h271.25z" />
								<path fill="#333" d="m376.5 241h-120.5c-8.284 0-15 6.716-15 15v120.5c0 8.284 6.716 15 15 15h120.5c8.284 0 15-6.716 15-15v-120.5c0-8.284-6.716-15-15-15zm-15 120.5h-90.5v-90.5h90.5z" />
								<path fill="#333" d="m75.25 271h120.5c8.284 0 15-6.716 15-15s-6.716-15-15-15h-120.5c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
								<path fill="#333" d="m75.25 331.25h120.5c8.284 0 15-6.716 15-15s-6.716-15-15-15h-120.5c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
								<path fill="#333" d="m75.25 391.5h120.5c8.284 0 15-6.716 15-15s-6.716-15-15-15h-120.5c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
								<path fill="#333" d="m375.894 421.75h-300.644c-8.284 0-15 6.716-15 15s6.716 15 15 15h300.644c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
							</g>
						</svg>
					</a>

					<a href="https://gitlab.com/Xotsu/newsscraper" target="_blank " rel="noopener noreferrer" >
						<h4>News Content Aggregator</h4>
					</a>
				</div>
				<div className="Example">
					<a href="https://center-map-point.herokuapp.com/" target="_blank" rel="noopener noreferrer" >
						<svg width="100" height="100" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
							viewBox="0 0 488.8 488.8" xmlspace="preserve">
							<g>
								<g>
									<path fill="#333" d="M244.252,97.4c-56.4,0-102.2,45.9-102.2,102.2s45.8,102.3,102.2,102.3s102.2-45.9,102.2-102.2S300.652,97.4,244.252,97.4z
			 M244.252,277.4c-42.9,0-77.7-34.9-77.7-77.7s34.9-77.7,77.7-77.7s77.7,34.9,77.7,77.7S287.152,277.4,244.252,277.4z"/>
									<path fill="#333" d="M244.252,0c-2.3,0-4.7,0-7,0.1c-98.6,3.4-180.1,80-189.5,178.1c-1.8,19-0.9,38.1,2.6,56.3c0,0,0.3,2.3,1.4,6.6
			c3,13.5,7.5,26.6,13.2,38.8c20.3,48.1,64.8,122.2,161.4,202.4c5.1,4.2,11.5,6.5,18.1,6.5c6.6,0,13-2.3,18.1-6.5
			c96.5-80.1,141-154.3,161.2-202c5.8-12.5,10.3-25.7,13.3-39.1c0.6-2.4,1-4.4,1.3-6.3c2.4-12.3,3.6-24.9,3.6-37.5
			C441.752,88.6,353.152,0,244.252,0z M414.052,230.7c0,0.2-0.3,1.9-1,5c-2.7,11.8-6.6,23.3-11.8,34.7
			c-19.3,45.6-61.7,116.1-154.4,193c-0.9,0.7-1.8,0.9-2.5,0.9c-0.6,0-1.6-0.2-2.5-0.9c-92.7-77-135.2-147.5-154.6-193.4
			c-5.1-11-9-22.5-11.7-34.4c-0.6-2.6-0.9-4.2-1-4.7c0-0.2-0.1-0.4-0.1-0.6c-3.1-16.3-3.9-33.1-2.3-49.7c8.3-85.9,79.6-153,166-156
			c98.1-3.4,179.1,75.4,179.1,172.9C417.252,208.5,416.152,219.5,414.052,230.7z"/>
								</g>
							</g>
						</svg>
					</a>
					<a href="https://gitlab.com/Xotsu/heroku-gitlab-react-geographical-midpoint" target="_blank " rel="noopener noreferrer" >
						<h4>Geolocation Midpoint</h4>
					</a>


				</div>
				<div className="Example">
					<svg width="100" height="100" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
						viewBox="0 0 482.8 482.8" xmlspace="preserve">
						<g>
							<g>
								<path fill="#333" d="M255.2,209.3c-5.3,5.3-5.3,13.8,0,19.1c21.9,21.9,21.9,57.5,0,79.4l-115,115c-21.9,21.9-57.5,21.9-79.4,0l-17.3-17.3
			c-21.9-21.9-21.9-57.5,0-79.4l115-115c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-115,115C8.7,322.7,0,343.6,0,365.8
			c0,22.2,8.6,43.1,24.4,58.8l17.3,17.3c16.2,16.2,37.5,24.3,58.8,24.3s42.6-8.1,58.8-24.3l115-115c32.4-32.4,32.4-85.2,0-117.6
			C269.1,204,260.5,204,255.2,209.3z"/>
								<path fill="#333" d="M458.5,58.2l-17.3-17.3c-32.4-32.4-85.2-32.4-117.6,0l-115,115c-32.4,32.4-32.4,85.2,0,117.6c5.3,5.3,13.8,5.3,19.1,0
			s5.3-13.8,0-19.1c-21.9-21.9-21.9-57.5,0-79.4l115-115c21.9-21.9,57.5-21.9,79.4,0l17.3,17.3c21.9,21.9,21.9,57.5,0,79.4l-115,115
			c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l115-115c15.7-15.7,24.4-36.6,24.4-58.8
			C482.8,94.8,474.2,73.9,458.5,58.2z"/>
							</g>
						</g>
					</svg>
					<a href="https://gitlab.com/Xotsu/shorturl-frontend" target="_blank " rel="noopener noreferrer" >
						<h4>URL Shortener</h4>
					</a>
				</div>
				<div className="Example">
					<svg width="100" height="100" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
						viewBox="0 0 487.222 487.222" xmlspace="preserve">
						<g>
							<path fill="#333" d="M486.554,186.811c-1.6-4.9-5.8-8.4-10.9-9.2l-152-21.6l-68.4-137.5c-2.3-4.6-7-7.5-12.1-7.5l0,0c-5.1,0-9.8,2.9-12.1,7.6
		l-67.5,137.9l-152,22.6c-5.1,0.8-9.3,4.3-10.9,9.2s-0.2,10.3,3.5,13.8l110.3,106.9l-25.5,151.4c-0.9,5.1,1.2,10.2,5.4,13.2
		c2.3,1.7,5.1,2.6,7.9,2.6c2.2,0,4.3-0.5,6.3-1.6l135.7-71.9l136.1,71.1c2,1,4.1,1.5,6.2,1.5l0,0c7.4,0,13.5-6.1,13.5-13.5
		c0-1.1-0.1-2.1-0.4-3.1l-26.3-150.5l109.6-107.5C486.854,197.111,488.154,191.711,486.554,186.811z M349.554,293.911
		c-3.2,3.1-4.6,7.6-3.8,12l22.9,131.3l-118.2-61.7c-3.9-2.1-8.6-2-12.6,0l-117.8,62.4l22.1-131.5c0.7-4.4-0.7-8.8-3.9-11.9
		l-95.6-92.8l131.9-19.6c4.4-0.7,8.2-3.4,10.1-7.4l58.6-119.7l59.4,119.4c2,4,5.8,6.7,10.2,7.4l132,18.8L349.554,293.911z"/>
						</g>
					</svg>
					<a href="https://gitlab.com/Xotsu/a-star-solving-wilsons-algorithm" target="_blank " rel="noopener noreferrer" >
						<h4>A* Pathfinding On Wilson's Algorithm</h4>
					</a>
				</div>
				<div className="Example">
					<svg width="100" height="100" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
						viewBox="0 0 508 508" xmlspace="preserve">
						<g>
							<g>
								<polygon fill="#333" points="376.9,8.2 376.9,24.6 406.3,24.6 237.6,193.3 155.7,111.3 51.6,215.4 63.1,227 155.7,134.5 237.6,216.4 
			417.9,36.2 417.9,65.5 434.3,65.5 434.3,8.2 		"/>
							</g>
						</g>
						<g>
							<g>
								<path fill="#333" d="M491.6,483.4L491.6,483.4V122.9h-81.9v360.5h-16.4V172.1h-81.9v311.4H295V286.8h-82v196.6h-16.4V237.6h-81.9v245.8H98.3
			V368.7H16.4v114.7H0v16.4h508v-16.4H491.6z M82,483.4H32.8v-98.3H82V483.4z M180.3,483.4h-49.2V254h49.2V483.4z M278.6,483.5
			h-49.2V303.2h49.2V483.5z M376.9,483.5h-49.2v-295h49.2V483.5z M475.3,483.4h-49.2V139.3h49.2V483.4z"/>
							</g>
						</g>
					</svg>
					<a href="https://gitlab.com/Xotsu/neural-network-binary-addition" target="_blank " rel="noopener noreferrer" >
						<h4>Supervised Deep Learning</h4>
					</a>
				</div>
				<div className="Example">
					<svg width="100" height="100" version="1.1" id="Artwork" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
						viewBox="0 0 512 512" enable-background="new 0 0 512 512" xmlspace="preserve">
						<g>
							<path fill="#333" d="M381,326.8h48.8c6.8,0,12.3-5.5,12.3-12.3s-5.5-12.3-12.3-12.3H381v-32.8h86.7c18.7,0,33.8-15.2,33.8-33.8v-35.7
		c0-6.8-5.5-12.3-12.3-12.3c-6.8,0-12.3,5.5-12.3,12.3v35.7c0,5.1-4.2,9.3-9.3,9.3H381v-32.8h48.8c6.8,0,12.3-5.5,12.3-12.3
		c0-6.8-5.5-12.3-12.3-12.3H381v-45.1c0-6.8-5.5-12.3-12.3-12.3h-45.1V82.8c0-6.8-5.5-12.3-12.3-12.3s-12.3,5.5-12.3,12.3v47.6
		h-32.8V44.9c0-18.7-15.2-33.8-33.8-33.8h-35.7c-6.8,0-12.3,5.5-12.3,12.3s5.5,12.2,12.3,12.2h35.7c5.1,0,9.3,4.2,9.3,9.3v85.4
		h-32.8V82.8c0-6.8-5.5-12.3-12.3-12.3c-6.8,0-12.3,5.5-12.3,12.3v47.6h-45.1c-6.8,0-12.3,5.5-12.3,12.3v45.1H82.2
		c-6.8,0-12.3,5.5-12.3,12.3c0,6.8,5.5,12.3,12.3,12.3h45.1V245H44.3c-18.7,0-33.8,15.2-33.8,33.8v35.7c0,6.8,5.5,12.3,12.2,12.3
		S35,321.3,35,314.6v-35.7c0-5.1,4.2-9.3,9.3-9.3h82.9v32.8H82.2c-6.8,0-12.3,5.5-12.3,12.3s5.5,12.3,12.3,12.3h45.1v45.1
		c0,6.8,5.5,12.3,12.3,12.3h45.1v45.1c0,6.8,5.5,12.3,12.3,12.3c6.8,0,12.3-5.5,12.3-12.3v-45.1h32.8v82.9
		c0,18.7,15.2,33.8,33.8,33.8h35.7c6.8,0,12.3-5.5,12.3-12.3s-5.5-12.3-12.3-12.3h-35.7c-5.1,0-9.3-4.2-9.3-9.3v-82.9h32.8v45.1
		c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-45.1h45.1c6.8,0,12.3-5.5,12.3-12.3V326.8z M356.5,359.6H151.7V154.9h204.8V359.6z"
							/>
							<path fill="#333" d="M193.2,330.4h121.9c6.8,0,12.3-5.5,12.3-12.3V196.3c0-6.8-5.5-12.3-12.3-12.3H193.2c-6.8,0-12.3,5.5-12.3,12.3v121.9
		C180.9,325,186.4,330.4,193.2,330.4z M205.4,208.6h97.4v97.4h-97.4V208.6z"/>
						</g>
					</svg>
					<a href="https://gitlab.com/Xotsu/neural-network-subway-smurfers" target="_blank " rel="noopener noreferrer" >
						<h4>Genetic Algorithm Machine Learning</h4>
					</a>
				</div>
				<div className="Example">
					<svg width="100" height="100" version="1.1" id="Artwork" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
						viewBox="0 0 512 512" enable-background="new 0 0 512 512" xmlspace="preserve">
						<g>
							<path fill="#333" d="M381.5,11.3h-251c-30.4,0-55.1,24.7-55.1,55.1v379.2c0,30.4,24.7,55.1,55.1,55.1h251c30.4,0,55.1-24.7,55.1-55.1V66.4
		C436.6,36,411.9,11.3,381.5,11.3z M412.1,445.6c0,16.9-13.7,30.6-30.6,30.6h-251c-16.9,0-30.6-13.7-30.6-30.6V66.4
		c0-16.9,13.7-30.6,30.6-30.6h251c16.9,0,30.6,13.7,30.6,30.6V445.6z"/>
							<path fill="#333" d="M372.2,67.2H139.8c-6.8,0-12.3,5.5-12.3,12.3V240c0,6.8,5.5,12.3,12.3,12.3h232.5c6.8,0,12.3-5.5,12.3-12.3V79.5
		C384.5,72.7,379,67.2,372.2,67.2z M360,227.7H152v-136h208V227.7z"/>
							<path fill="#333" d="M256,278.1c-48.6,0-88.2,39.6-88.2,88.2c0,48.6,39.6,88.2,88.2,88.2s88.2-39.6,88.2-88.2
		C344.2,317.7,304.6,278.1,256,278.1z M256,430c-35.1,0-63.7-28.6-63.7-63.7c0-35.1,28.6-63.7,63.7-63.7s63.7,28.6,63.7,63.7
		C319.7,401.4,291.1,430,256,430z"/>
						</g>
					</svg>					<h4>Spotify To YouTube Playlist Converter</h4>
				</div>
				<div className="Example">
					<svg width="100" height="100" version="1.1" id="Artwork" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
						viewBox="0 0 512 512" enable-background="new 0 0 512 512" xmlspace="preserve">
						<g>
							<path fill="#333" d="M182.4,251.5c-28.9,0-52.5,23.5-52.5,52.5s23.5,52.5,52.5,52.5c28.9,0,52.5-23.5,52.5-52.5S211.3,251.5,182.4,251.5z
		 M182.4,331.9c-15.4,0-28-12.5-28-28s12.5-28,28-28c15.4,0,28,12.5,28,28S197.8,331.9,182.4,331.9z"/>
							<path fill="#333" d="M382.1,303.9c0-28.9-23.5-52.5-52.5-52.5c-28.9,0-52.5,23.5-52.5,52.5s23.5,52.5,52.5,52.5
		C358.6,356.4,382.1,332.8,382.1,303.9z M329.6,331.9c-15.4,0-28-12.5-28-28s12.5-28,28-28c15.4,0,28,12.5,28,28
		S345.1,331.9,329.6,331.9z"/>
							<path fill="#333" d="M467.8,173.6c-8.4-50-49.7-87-100.6-90.1l-2.1-0.4c-0.7-0.1-1.4-0.2-2.1-0.2H151.5c-53.3,0-98.4,38.1-107.2,90.4L12,346.4
		c-1,4.7-1.5,9.4-1.5,14.1c0,37.8,30.7,68.5,68.5,68.5c22.8,0,44.1-11.3,56.6-29.9l15.6-21.6c4-5.5,2.7-13.1-2.8-17.1
		c-5.5-4-13.1-2.7-17.1,2.8l-15.8,21.9c-8.2,12.2-21.9,19.5-36.5,19.5c-24.3,0-44-19.8-44-44c0-3,0.3-6.1,1-9.4l32.3-173.5
		c6.8-40.7,41.8-70.2,83.1-70.2H362l1.7,0.3c0.5,0.1,0.9,0.1,1.4,0.2c39,2.1,72,31.5,78.5,69.9L476,351.4c0.6,3,0.9,6.1,0.9,9.1
		c0,24.3-19.8,44-44,44c-14.7,0-28.3-7.3-36.8-19.8l-16.8-23.2c-4-5.5-11.6-6.7-17.1-2.8c-5.5,4-6.7,11.6-2.8,17.1l16.5,22.9
		c12.8,18.9,34,30.2,56.8,30.2c37.8,0,68.5-30.7,68.5-68.5c0-4.7-0.5-9.5-1.4-13.9L467.8,173.6z"/>
							<circle fill="#333" cx="319.5" cy="181.8" r="16.6" />
							<circle fill="#333" cx="387.4" cy="181.8" r="16.6" />
							<path fill="#333" d="M159.7,132.6c-6.8,0-12.3,5.5-12.3,12.3V167h-22.1c-6.8,0-12.3,5.5-12.3,12.3s5.5,12.3,12.3,12.3h22.1v22.1
		c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-22.1h22.1c6.8,0,12.3-5.5,12.3-12.3s-5.5-12.3-12.3-12.3H172v-22.1
		C172,138.1,166.5,132.6,159.7,132.6z"/>
							<circle fill="#333" cx="353.4" cy="215.7" r="16.6" />
							<circle fill="#333" cx="353.4" cy="147.8" r="16.6" />
						</g>
					</svg>					<h4> Games with P5.js, 2D Physics, Unity</h4>
				</div>
			</div >
		</div >
	);
}
export default ProjectItems;