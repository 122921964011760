import React from 'react';
import './App.css';
import ProjectItems from './ProjectItems.js';
import Navigation from './Nav.js';

function App() {
  return (
    <div className="Main" id="Start">
      <Navigation />
      <div className="MainBody">
        <div className="Title">
          <h1>Justinas Naumenka</h1>
          <p>Full Stack Software Developer</p>
        </div>
        <div className="AboutT" >
          <h2>About Me</h2>
          <p className="AboutP">
            I am a highly capable full stack developer with experience programming everything from web API's to machine learning.
            <br />
            My diverse background allows me to tackle a wide range of problems with efficient solutions.
          </p>
        </div>
        <div className="Recent">
          <h2 className="RecentH">My Most Recent Projects Include</h2>
        </div>
        <div className="ProItems">
          <ProjectItems />
        </div>
        <div className="Contact" id="Contact">
          <div>
            <h2>Contact</h2>
            <p>
              <a href="mailto:Justinas@Naumenka.com">
                Email
              </a>
              <br />
              <a href="https://gitlab.com/Xotsu" style={{ "color": "blue" }}>Gitlab</a>
              <br />
              Copyright © naumenka.com 2020</p>


          </div>

        </div>
      </div >
    </div >
  );
}

export default App;
