import React from 'react';
import './App.css';


function Navigation() {
	return (
		<div className="Nav">
			<a href="#Start">
				<svg className="NavLinks" width="15" height="15" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					viewBox="0 0 16 16" xmlspace="preserve">
					<g>
						<path fill="whitesmoke" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
						<path fill="whitesmoke" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" />
					</g>
				</svg>
			</a>
			<a href="#Contact">
				<svg className="NavLinks" width="15" height="15" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					viewBox="0 0 483.3 483.3" xmlspace="preserve">
					<g>
						<g>
							<path fill="whitesmoke" d="M424.3,57.75H59.1c-32.6,0-59.1,26.5-59.1,59.1v249.6c0,32.6,26.5,59.1,59.1,59.1h365.1c32.6,0,59.1-26.5,59.1-59.1
			v-249.5C483.4,84.35,456.9,57.75,424.3,57.75z M456.4,366.45c0,17.7-14.4,32.1-32.1,32.1H59.1c-17.7,0-32.1-14.4-32.1-32.1v-249.5
			c0-17.7,14.4-32.1,32.1-32.1h365.1c17.7,0,32.1,14.4,32.1,32.1v249.5H456.4z"/>
							<path fill="whitesmoke" d="M304.8,238.55l118.2-106c5.5-5,6-13.5,1-19.1c-5-5.5-13.5-6-19.1-1l-163,146.3l-31.8-28.4c-0.1-0.1-0.2-0.2-0.2-0.3
			c-0.7-0.7-1.4-1.3-2.2-1.9L78.3,112.35c-5.6-5-14.1-4.5-19.1,1.1c-5,5.6-4.5,14.1,1.1,19.1l119.6,106.9L60.8,350.95
			c-5.4,5.1-5.7,13.6-0.6,19.1c2.7,2.8,6.3,4.3,9.9,4.3c3.3,0,6.6-1.2,9.2-3.6l120.9-113.1l32.8,29.3c2.6,2.3,5.8,3.4,9,3.4
			c3.2,0,6.5-1.2,9-3.5l33.7-30.2l120.2,114.2c2.6,2.5,6,3.7,9.3,3.7c3.6,0,7.1-1.4,9.8-4.2c5.1-5.4,4.9-14-0.5-19.1L304.8,238.55z"
							/>
						</g>
					</g>
				</svg>
			</a>
			<a href="https://gitlab.com/Xotsu/" target="_blank" rel="noopener noreferrer" >
				<svg className="NavLinks" width="15" height="15" version="1.1" id="Artwork" xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					viewBox="0 0 512 512" enable-background="new 0 0 512 512" xmlspace="preserve">
					<g>
						<path fill="whitesmoke" d="M12.3,95.5v321.1c0,20.3,16.5,36.9,36.9,36.9h413.7c20.3,0,36.8-16.5,36.8-36.9V95.5c0-20.3-16.5-36.9-36.8-36.9H49.2
		C28.9,58.6,12.3,75.1,12.3,95.5z M462.8,428.9H49.2c-6.8,0-12.4-5.5-12.4-12.4V175.7h438.3v240.9
		C475.2,423.3,469.6,428.9,462.8,428.9z M475.2,95.5v55.7H36.8V95.5c0-6.8,5.5-12.4,12.4-12.4h413.7
		C469.6,83.1,475.2,88.7,475.2,95.5z"/>
						<path fill="whitesmoke" d="M70.9,129.4c3.2,0,6.4-1.3,8.7-3.6c2.3-2.3,3.6-5.4,3.6-8.7c0-3.2-1.3-6.4-3.6-8.7c-4.6-4.6-12.8-4.6-17.3,0
		c-2.3,2.3-3.6,5.4-3.6,8.7c0,3.2,1.3,6.4,3.6,8.7C64.5,128.1,67.6,129.4,70.9,129.4z"/>
						<path fill="whitesmoke" d="M117.2,129.4c3.2,0,6.4-1.3,8.6-3.6c2.3-2.3,3.6-5.4,3.6-8.7c0-3.2-1.3-6.4-3.6-8.7c-4.6-4.6-12.7-4.6-17.3,0
		c-2.3,2.3-3.6,5.4-3.6,8.7c0,3.2,1.3,6.4,3.6,8.7C110.7,128.1,113.9,129.4,117.2,129.4z"/>
						<path fill="whitesmoke" d="M163.4,129.4c3.2,0,6.4-1.3,8.7-3.6c2.3-2.3,3.6-5.4,3.6-8.7c0-3.2-1.3-6.4-3.6-8.7c-4.6-4.6-12.8-4.6-17.3,0
		c-2.3,2.3-3.6,5.4-3.6,8.7c0,3.2,1.3,6.4,3.6,8.7C157,128.1,160.2,129.4,163.4,129.4z"/>
						<path fill="whitesmoke" d="M337.6,232.3c-5.2-4.4-12.9-3.7-17.3,1.4c-4.4,5.2-3.7,12.9,1.4,17.3l61.8,52.3l-61.8,52.5c-5.2,4.4-5.8,12.1-1.4,17.3
		c2.4,2.9,5.9,4.3,9.3,4.3c2.8,0,5.6-1,7.9-2.9l72.9-61.8c2.7-2.3,4.3-5.7,4.3-9.4c0-3.6-1.6-7-4.3-9.3L337.6,232.3z"/>
						<path fill="whitesmoke" d="M128.4,303.3l61.7-52.3c5.2-4.4,5.8-12.1,1.4-17.3c-4.4-5.2-12.1-5.8-17.3-1.4l-72.8,61.6c-2.7,2.3-4.3,5.7-4.3,9.3
		c0,3.6,1.6,7,4.3,9.3l72.8,61.8c2.3,2,5.1,2.9,7.9,2.9c3.5,0,6.9-1.5,9.3-4.3c4.4-5.2,3.8-12.9-1.4-17.3L128.4,303.3z"/>
						<path fill="whitesmoke" d="M291.1,214.9c-6.3-2.5-13.4,0.5-15.9,6.8L213.5,376c-2.5,6.3,0.5,13.4,6.8,15.9c1.5,0.6,3,0.9,4.5,0.9
		c4.9,0,9.5-2.9,11.4-7.7l61.7-154.3C300.5,224.5,297.4,217.4,291.1,214.9z"/>
					</g>
				</svg>
			</a>


		</div >
	)
}
export default Navigation;